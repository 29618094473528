.HelpConnectMain{
    padding: 94px 0px;
    background-size: cover;
    background-position: center;
}


.HelpConnectMain h3{
    color: black;
    font-size: 54px;
    font-family: "CenturyBoldSWFTE";
    font-weight: 700;
    line-height: 66px;
    word-wrap: break-word;
    text-align: center;
    margin-bottom: 40px;
}

.HelpConnectMain p{
    color: #5F504D;
    font-size: 24px;
    font-family: "Gotham-Book";
    font-weight: 400;
    line-height: 30px;
    word-wrap: break-word;
    margin-bottom: 80px;
}