@import "../../../assets/css/global.css";


section{
    padding: 94px 0px;
}


h3{
    color: black;
    font-size: 54px;
    font-family: "CenturyBoldSWFTE";
    font-weight: 700;
    line-height: 66px;
    word-wrap: break-word;
    text-align: center;
}

.subtitle{
    color: #5F504D;
    font-size: 28px;
    font-family: "Gotham-Medium";
    font-weight: 500;
    line-height: 35px;
    word-wrap: break-word;
    text-align: center;
    padding-top: 20px;
    margin-bottom: 52px;
}


.swiper-slide .col-md-3.col-sm-12{
    padding: 0px 6px;
    /* padding-right: 14px; */
}

.swiper-slide .row{
    padding: 0 9px;
}

.swiper-slide .card{
    width: 100%;
    height: 426px;
    background: rgba(255, 255, 255, 0.60);
    box-shadow: 0px 4px 45px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    border: 0.5px rgb(197 191 191 / 50%) solid;
}

.swiper-slide .card img{
    margin-bottom: 35px;
}

.swiper-slide .card .card-body {
    display: flex;
    align-items: center;
    /* justify-content: center; */
    flex-direction: column;
    text-align: center;
}

.swiper-slide .card .card-body figure{
    margin-top: 35px;
}

.swiper-slide .card .card-body figure img{
    width: 65px;
    height: 65px;
}

.swiper-slide .card .card-body .card-title{
    color: #535050;
    font-size: 29px;
    font-family: "CenturyRegularSWFTE";
    font-weight: 400;
    height: 72px;
    text-transform: capitalize;
    line-height: 34px;
    word-wrap: break-word;
    margin-bottom: 30px;
    width: 100%;
}

.swiper-slide .card .card-body .card-title span {
    position: absolute;
    left: 0;
    right: 0;
}
.swiper-slide .card .card-body .card-text{
    color: #535050;
    font-size: 16px;
    font-family: "Gotham-Light";
    font-weight: 300;
    text-transform: capitalize;
    line-height: 22px;
    word-wrap: break-word
}