@import "../../assets/css/global.css";

header {
    background: linear-gradient(180deg, #F6F6F6 0%, white 100%)
}

.navbar-nav li{
    margin-right: 20px;
}

.navbar-nav li:last-child{
    margin-right: 0px;
}

.headerButton {
    display: flex;
    align-items: center;
    background: rgba(95, 80, 77, 0.10);
    border-radius: 5.22px;
    border: 0px;
    /* padding: 8px 17px; */
    width: 266px;
    height: 61px;
    justify-content: center;
}


.headerButton.btn3 .headerButtonText{
    margin-left: 14px;
}

.headerButton .headerButtonText{
    display: flex;
    flex-direction: column;
    color: #535050;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    word-wrap: break-word;
    font-family: 'Gotham-Book';
}

.headerButton .headerButtonText span:first-child{
    margin-bottom: 2px;
}

.headerButton.active {
    background: #C10230;
}

.headerButton.active .headerButtonText{
    color: #fff;
}

.sideMenuBtn{
    background-color: transparent;
    border: 0px;
    margin-right: 40px;
}