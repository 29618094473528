@import url('https://fonts.cdnfonts.com/css/century-schoolbook');

.kennedy-redo {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.kennedy-redo .div {
  background-color: #ffffff;
  overflow: hidden;
  width: 1600px;
  height: 6955px;
  position: relative;
}

.kennedy-redo .overlap {
  position: absolute;
  width: 1296px;
  height: 438px;
  top: 5252px;
  left: 159px;
}

.kennedy-redo .group {
  position: absolute;
  width: 1296px;
  height: 438px;
  top: 0;
  left: 0;
}

.kennedy-redo .overlap-group {
  position: relative;
  height: 438px;
  border-radius: 20px;
}

.kennedy-redo .rectangle {
  position: absolute;
  width: 1297px;
  height: 438px;
  top: 0;
  left: 0;
  background-color: var(--orange);
  border-radius: 20px;
  box-shadow: 0px 0px 12px #00000040;
}

.kennedy-redo .rectangle-2 {
  position: absolute;
  width: 1296px;
  height: 438px;
  top: 0;
  left: 0;
  border-radius: 20px;
  box-shadow: 0px 0px 12px #00000026;
}

.kennedy-redo .mask-group {
  position: absolute;
  width: 1282px;
  height: 437px;
  top: 1px;
  left: 1px;
}

.kennedy-redo .group-2 {
  position: absolute;
  width: 587px;
  height: 201px;
  top: 108px;
  left: 105px;
}

.kennedy-redo .group-wrapper {
  height: 201px;
}

.kennedy-redo .div-wrapper {
  width: 587px;
  height: 201px;
}

.kennedy-redo .group-3 {
  position: relative;
  width: 589px;
  height: 201px;
}

.kennedy-redo .don-t-waste-time-on {
  position: absolute;
  width: 587px;
  top: 0;
  left: 0;
  font-family: "Gotham-Medium", Helvetica;
  font-weight: 500;
  color: var(--variable-collection-WHITE);
  font-size: 28px;
  letter-spacing: 0;
  line-height: 42px;
}

.kennedy-redo .frame-wrapper {
  position: absolute;
  width: 274px;
  height: 55px;
  top: 146px;
  left: 294px;
}

.kennedy-redo .frame {
  display: inline-flex;
  align-items: center;
  gap: 14.03px;
  position: relative;
}

.kennedy-redo .line {
  position: relative;
  width: 28.05px;
  height: 1.4px;
}

.kennedy-redo .frame-2 {
  display: inline-flex;
  align-items: flex-start;
  gap: 21.04px;
  position: relative;
  flex: 0 0 auto;
}

.kennedy-redo .jaquon-hart-wrapper {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.4px;
  position: relative;
  flex: 0 0 auto;
}

.kennedy-redo .jaquon-hart {
  position: relative;
  width: fit-content;
  margin-top: -1.4px;
  font-family: "Gotham-Book", Helvetica;
  font-weight: 400;
  color: var(--variable-collection-WHITE);
  font-size: 28.1px;
  letter-spacing: 0;
  line-height: 54.7px;
  white-space: nowrap;
}

.kennedy-redo .group-4 {
  position: absolute;
  width: 414px;
  height: 318px;
  top: 57px;
  left: 795px;
  background-color: var(--variable-collection-WHITE);
  border-radius: 20px;
}

.kennedy-redo .frame-3 {
  display: flex;
  flex-direction: column;
  width: 256px;
  height: 244px;
  align-items: center;
  justify-content: space-between;
  position: relative;
  top: 40px;
  left: 75px;
}

.kennedy-redo .frame-4 {
  display: inline-flex;
  flex-direction: column;
  height: 165px;
  align-items: center;
  gap: 18px;
  position: relative;
  margin-left: -14.71px;
  margin-right: -14.71px;
}

.kennedy-redo .text-wrapper {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: "Century Schoolbook";
  font-weight: 700;
  color: #000000;
  font-size: 44px;
  text-align: center;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
}

.kennedy-redo .p {
  position: relative;
  width: 285px;
  font-family: var(--body-copy-font-family);
  font-weight: var(--body-copy-font-weight);
  color: var(--variable-collection-other-logo-design);
  font-size: var(--body-copy-font-size);
  text-align: center;
  letter-spacing: var(--body-copy-letter-spacing);
  line-height: var(--body-copy-line-height);
  font-style: var(--body-copy-font-style);
}

.kennedy-redo .frame-5 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 15px 50px;
  position: relative;
  flex: 0 0 auto;
  margin-left: -35.21px;
  margin-right: -35.21px;
  background-color: var(--red);
  border-radius: 14px;
  overflow: hidden;
}

.kennedy-redo .text-wrapper-2 {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: var(--button-text-font-family);
  font-weight: var(--button-text-font-weight);
  color: var(--variable-collection-WHITE);
  font-size: var(--button-text-font-size);
  letter-spacing: var(--button-text-letter-spacing);
  line-height: var(--button-text-line-height);
  white-space: nowrap;
  font-style: var(--button-text-font-style);
}

.kennedy-redo .overlap-2 {
  position: absolute;
  width: 1600px;
  height: 766px;
  top: 1729px;
  left: 0;
  background-image: url(./img/group-1597879830.png);
  background-size: 100% 100%;
}

.kennedy-redo .what-are-your {
  position: absolute;
  top: 112px;
  left: 216px;
  font-family: "Century Schoolbook";
  font-weight: var(--h1-font-weight);
  color: transparent;
  font-size: var(--h1-font-size);
  letter-spacing: var(--h1-letter-spacing);
  line-height: var(--h1-line-height);
  white-space: nowrap;
  font-style: var(--h1-font-style);
}

.kennedy-redo .span {
  color: #000000;
  font-family: "Century Schoolbook";
  font-style: var(--h1-font-style);
  font-weight: var(--h1-font-weight);
  letter-spacing: var(--h1-letter-spacing);
  line-height: var(--h1-line-height);
  font-size: var(--h1-font-size);
}

.kennedy-redo .text-wrapper-3 {
  color: #f88d2a;
  font-family: "Century Schoolbook";
  font-style: var(--h1-font-style);
  font-weight: var(--h1-font-weight);
  letter-spacing: var(--h1-letter-spacing);
  line-height: var(--h1-line-height);
  font-size: var(--h1-font-size);
}

.kennedy-redo .text-wrapper-4 {
  position: absolute;
  width: 825px;
  top: 191px;
  left: 216px;
  font-family: var(--h3-font-family);
  font-weight: var(--h3-font-weight);
  color: var(--variable-collection-dark-gray);
  font-size: var(--h3-font-size);
  letter-spacing: var(--h3-letter-spacing);
  line-height: var(--h3-line-height);
  font-style: var(--h3-font-style);
}

.kennedy-redo .frame-6 {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 19px;
  position: absolute;
  top: 291px;
  left: 216px;
}

.kennedy-redo .what-cause-do-i-want {
  position: relative;
  align-self: stretch;
  margin-top: -1px;
  font-family: "Gotham-Medium", Helvetica;
  font-weight: 400;
  color: var(--variable-collection-other-logo-design);
  font-size: 28px;
  letter-spacing: 0;
  line-height: 35px;
}

.kennedy-redo .text-wrapper-5 {
  font-weight: 500;
}

.kennedy-redo .text-wrapper-6 {
  font-family: "Gotham-BoldItalic", Helvetica;
  font-weight: 700;
  font-style: italic;
}

.kennedy-redo .div-2 {
  position: relative;
  align-self: stretch;
  font-family: "Gotham-Bold", Helvetica;
  font-weight: 400;
  color: var(--variable-collection-other-logo-design);
  font-size: 28px;
  letter-spacing: 0;
  line-height: 35px;
}

.kennedy-redo .text-wrapper-7 {
  font-family: var(--h4-font-family);
  font-weight: var(--h4-font-weight);
  font-style: var(--h4-font-style);
  letter-spacing: var(--h4-letter-spacing);
  line-height: var(--h4-line-height);
  font-size: var(--h4-font-size);
}

.kennedy-redo .div-3 {
  position: relative;
  align-self: stretch;
  font-family: "Gotham-Medium", Helvetica;
  font-weight: 400;
  color: var(--variable-collection-other-logo-design);
  font-size: 28px;
  letter-spacing: 0;
  line-height: 35px;
}

.kennedy-redo .img {
  position: absolute;
  width: 451px;
  height: 268px;
  top: 293px;
  left: 1052px;
}

.kennedy-redo .overlap-3 {
  position: absolute;
  width: 1600px;
  height: 887px;
  top: 0;
  left: 0;
}

.kennedy-redo .mask-group-2 {
  position: absolute;
  width: 1600px;
  height: 770px;
  top: 117px;
  left: 0;
  background-size: contain;
}

.kennedy-redo .there-are-so-many {
  position: absolute;
  width: 602px;
  top: 253px;
  left: 223px;
  font-family: var(--h3-font-family);
  font-weight: var(--h3-font-weight);
  color: var(--variable-collection-WHITE);
  font-size: var(--h3-font-size);
  letter-spacing: var(--h3-letter-spacing);
  line-height: var(--h3-line-height);
  font-style: var(--h3-font-style);
}

.kennedy-redo .what-difference-do {
  position: absolute;
  width: 717px;
  height: 140px;
  top: 345px;
  left: 223px;
  font-family: "Century Schoolbook";
  font-weight: var(--h1-font-weight);
  color: transparent;
  font-size: var(--h1-font-size);
  letter-spacing: var(--h1-letter-spacing);
  line-height: var(--h1-line-height);
  font-style: var(--h1-font-style);
}

.kennedy-redo .text-wrapper-8 {
  color: #ffffff;
  font-family: "Century Schoolbook";
  font-style: var(--h1-font-style);
  font-weight: var(--h1-font-weight);
  letter-spacing: var(--h1-letter-spacing);
  line-height: var(--h1-line-height);
  font-size: var(--h1-font-size);
}

.kennedy-redo .navbar {
  position: absolute;
  width: 1600px;
  height: 118px;
  top: 0;
  left: 0;
  background: linear-gradient(180deg, rgb(246, 246, 246) 0%, rgb(255, 255, 255) 100%);
}

.kennedy-redo .group-5 {
  position: relative;
  width: 1398px;
  height: 83px;
  top: 17px;
  left: 102px;
}

.kennedy-redo .frame-7 {
  display: flex;
  width: 1398px;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.kennedy-redo .frame-8 {
  display: inline-flex;
  align-items: center;
  gap: 40px;
  position: relative;
  flex: 0 0 auto;
}

.kennedy-redo .group-6 {
  position: relative;
  width: 25.51px;
  height: 20px;
}

.kennedy-redo .group-7 {
  position: relative;
  width: 26px;
  height: 20px;
}

.kennedy-redo .rectangle-3 {
  position: absolute;
  width: 26px;
  height: 3px;
  top: 0;
  left: 0;
  background-color: var(--variable-collection-other-logo-design);
  border-radius: 47px;
}

.kennedy-redo .rectangle-4 {
  position: absolute;
  width: 20px;
  height: 3px;
  top: 8px;
  left: 5px;
  background-color: var(--variable-collection-other-logo-design);
  border-radius: 47px;
}

.kennedy-redo .rectangle-5 {
  position: absolute;
  width: 15px;
  height: 3px;
  top: 17px;
  left: 9px;
  background-color: var(--variable-collection-other-logo-design);
  border-radius: 47px;
}

.kennedy-redo .horizontal-logo {
  position: relative;
  width: 234.77px;
  height: 70px;
  margin-bottom: -10px !important;
}

.kennedy-redo .frame-9 {
  display: inline-flex;
  align-items: flex-start;
  gap: 20px;
  position: relative;
  flex: 0 0 auto;
}

.kennedy-redo .individual {
  position: relative;
  width: 266px;
  height: 61px;
  background-color: var(--red);
  border-radius: 5.26px;
}

.kennedy-redo .overlap-group-wrapper {
  position: relative;
  width: 175px;
  height: 52px;
  top: 3px;
  left: 45px;
}

.kennedy-redo .overlap-group-2 {
  position: relative;
  width: 179px;
  height: 52px;
}

.kennedy-redo .group-8 {
  position: absolute;
  width: 129px;
  height: 31px;
  top: 10px;
  left: 50px;
}

.kennedy-redo .text-wrapper-9 {
  position: absolute;
  top: 0;
  left: 0;
  font-family: "Gotham-Book", Helvetica;
  font-weight: 400;
  color: #ffffff;
  font-size: 15px;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
}

.kennedy-redo .text-wrapper-10 {
  position: absolute;
  top: 17px;
  left: 0;
  font-family: "Gotham-Book", Helvetica;
  font-weight: 400;
  color: #ffffff;
  font-size: 15px;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
}

.kennedy-redo .group-9 {
  position: absolute;
  width: 52px;
  height: 52px;
  top: 0;
  left: 0;
}

.kennedy-redo .corporate-employee {
  position: relative;
  width: 266px;
  height: 61px;
  background-color: #5f504d1a;
  border-radius: 5.22px;
}

.kennedy-redo .group-10 {
  position: relative;
  width: 232px;
  height: 45px;
  top: 8px;
  left: 17px;
}

.kennedy-redo .overlap-group-3 {
  position: relative;
  height: 46px;
}

.kennedy-redo .group-11 {
  position: absolute;
  width: 179px;
  height: 25px;
  top: 10px;
  left: 53px;
}

.kennedy-redo .group-12 {
  position: relative;
  width: 183px;
  height: 25px;
}

.kennedy-redo .text-wrapper-11 {
  position: absolute;
  top: 0;
  left: 0;
  font-family: "Gotham-Book", Helvetica;
  font-weight: 400;
  color: var(--variable-collection-other-logo-design);
  font-size: 14px;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
}

.kennedy-redo .text-wrapper-12 {
  position: absolute;
  width: 179px;
  top: 15px;
  left: 0;
  font-family: "Gotham-Book", Helvetica;
  font-weight: 400;
  color: var(--variable-collection-other-logo-design);
  font-size: 14px;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
}

.kennedy-redo .group-13 {
  position: absolute;
  width: 58px;
  height: 46px;
  top: 0;
  left: 0;
}

.kennedy-redo .ngos-looking-for {
  position: relative;
  width: 266px;
  height: 61px;
  background-color: #e7e5e5;
  border-radius: 5.22px;
}

.kennedy-redo .group-14 {
  position: relative;
  width: 207px;
  height: 48px;
  top: 7px;
  left: 30px;
}

.kennedy-redo .group-15 {
  position: absolute;
  width: 138px;
  height: 26px;
  top: 11px;
  left: 73px;
}

.kennedy-redo .text-wrapper-13 {
  position: absolute;
  width: 116px;
  top: 0;
  left: 0;
  font-family: "Gotham-Book", Helvetica;
  font-weight: 400;
  color: var(--variable-collection-other-logo-design);
  font-size: 14px;
  letter-spacing: 0;
  line-height: normal;
}

.kennedy-redo .text-wrapper-14 {
  position: absolute;
  width: 134px;
  top: 16px;
  left: 0;
  font-family: "Gotham-Book", Helvetica;
  font-weight: 400;
  color: var(--variable-collection-other-logo-design);
  font-size: 14px;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
}

.kennedy-redo .group-16 {
  position: absolute;
  width: 59px;
  height: 48px;
  top: 0;
  left: 0;
}

.kennedy-redo .flexcontainer {
  width: 292px;
  height: 75px;
  top: 539px;
  left: 215px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 11px;
  position: absolute;
}

.kennedy-redo .text {
  position: relative;
  align-self: stretch;
  font-family: var(--body-copy-font-family);
  font-weight: var(--body-copy-font-weight);
  color: var(--variable-collection-WHITE);
  font-size: var(--body-copy-font-size);
  letter-spacing: var(--body-copy-letter-spacing);
  line-height: var(--body-copy-line-height);
  font-style: var(--body-copy-font-style);
}

.kennedy-redo .text-wrapper-15 {
  font-family: var(--body-copy-font-family);
  font-weight: var(--body-copy-font-weight);
  color: #ffffff;
  font-size: var(--body-copy-font-size);
  letter-spacing: var(--body-copy-letter-spacing);
  line-height: var(--body-copy-line-height);
  font-style: var(--body-copy-font-style);
}

.kennedy-redo .TAKE-THE-NEXT-STEP-wrapper {
  width: 285px;
  top: 627px;
  left: 214px;
  display: flex;
  height: 57px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 15px 50px;
  position: absolute;
  background-color: var(--red);
  border-radius: 10px;
  overflow: hidden;
}

.kennedy-redo .TAKE-THE-NEXT-STEP {
  position: relative;
  width: fit-content;
  margin-top: -5px;
  margin-bottom: -3px;
  margin-left: -9.5px;
  margin-right: -9.5px;
  font-family: "Gotham-Bold", Helvetica;
  font-weight: 700;
  color: var(--variable-collection-WHITE);
  font-size: 18px;
  letter-spacing: 0;
  line-height: 35px;
  white-space: nowrap;
}

.kennedy-redo .group-17 {
  position: absolute;
  width: 104px;
  height: 20px;
  top: 820px;
  left: 748px;
}

.kennedy-redo .ellipse {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0;
  left: 0;
  background-color: #ffffff66;
  border-radius: 10px;
}

.kennedy-redo .ellipse-2 {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0;
  left: 28px;
  background-color: #ffffff66;
  border-radius: 10px;
}

.kennedy-redo .ellipse-3 {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0;
  left: 56px;
  background-color: #ffffff66;
  border-radius: 10px;
}

.kennedy-redo .ellipse-4 {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0;
  left: 84px;
  background-color: #ffffff66;
  border-radius: 10px;
}

.swiper-button-prev {
  background-image: url(./img/arrow-left-circle-1.svg) !important;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.swiper-button-next {
  background-image: url(./img/arrow-left-circle.svg) !important;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.swiper-button-prev::after,
.swiper-button-next::after {
  display: none;
}

.swiper-button-prev img {
  transform: rotate(180deg);
}

.kennedy-redo .group-18 {
  position: absolute;
  width: 1264px;
  height: 596px;
  top: 4455px;
  left: 165px;
}

.kennedy-redo .group-19 {
  position: relative;
  width: 1268px;
  height: 596px;
}

.kennedy-redo .arrow-left-circle {
  position: absolute;
  width: 35px;
  height: 35px;
  top: 330px;
  left: 0;
}

.kennedy-redo .arrow-left-circle-2 {
  position: absolute;
  width: 35px;
  height: 35px;
  top: 330px;
  left: 1229px;
}

.kennedy-redo .meet-our-actitude {
  position: absolute;
  top: 0;
  left: 201px;
  font-family: "Century Schoolbook";
  font-weight: var(--h1-font-weight);
  color: transparent;
  font-size: var(--h1-font-size);
  letter-spacing: var(--h1-letter-spacing);
  line-height: var(--h1-line-height);
  white-space: nowrap;
  font-style: var(--h1-font-style);
}

.kennedy-redo .meet-a-few-of-our {
  position: absolute;
  width: 646px;
  top: 91px;
  left: 311px;
  font-family: var(--h5-font-family);
  font-weight: var(--h5-font-weight);
  color: var(--variable-collection-dark-gray);
  font-size: var(--h5-font-size);
  text-align: center;
  letter-spacing: var(--h5-letter-spacing);
  line-height: var(--h5-line-height);
  font-style: var(--h5-font-style);
}

.kennedy-redo .actitude-volunteers {
  position: absolute;
  width: 334px;
  height: 365px;
  top: 231px;
  left: 81px;
}

.kennedy-redo .frame-10 {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
  position: relative;
  top: 4px;
  left: 40px;
}

.kennedy-redo .ellipse-5 {
  position: relative;
  width: 225px;
  height: 225px;
  object-fit: cover;
}

.kennedy-redo .frame-11 {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 7px;
  position: relative;
  flex: 0 0 auto;
}

.kennedy-redo .frame-12 {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  position: relative;
  flex: 0 0 auto;
}

.kennedy-redo .text-wrapper-16 {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: "Century Schoolbook";
  font-weight: 700;
  color: var(--variable-collection-other-logo-design);
  font-size: 30px;
  text-align: center;
  letter-spacing: 0;
  line-height: 34px;
  white-space: nowrap;
}

.kennedy-redo .line-2 {
  position: relative;
  width: 94px;
  height: 1px;
  object-fit: cover;
}

.kennedy-redo .caring-for-homeless {
  position: relative;
  width: fit-content;
  font-family: "Gotham-Light", Helvetica;
  font-weight: 300;
  color: var(--variable-collection-other-logo-design);
  font-size: 18px;
  text-align: center;
  letter-spacing: 0;
  line-height: 26px;
}

.kennedy-redo .group-20 {
  position: absolute;
  width: 334px;
  height: 365px;
  top: 231px;
  left: 465px;
}

.kennedy-redo .group-21 {
  position: absolute;
  width: 334px;
  height: 365px;
  top: 231px;
  left: 849px;
}

.kennedy-redo .frame-13 {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 25px;
  position: relative;
  top: 4px;
  left: 40px;
}

.kennedy-redo .overlap-4 {
  position: absolute;
  width: 1276px;
  height: 864px;
  top: 2563px;
  left: 161px;
}

.kennedy-redo .frame-14 {
  display: flex;
  flex-direction: column;
  width: 1276px;
  height: 328px;
  align-items: center;
  gap: 37px;
  position: absolute;
  top: 0;
  left: 0;
}

.kennedy-redo .let-s-help-you {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: "Century Schoolbook";
  font-weight: var(--h1-font-weight);
  color: transparent;
  font-size: var(--h1-font-size);
  text-align: center;
  letter-spacing: var(--h1-letter-spacing);
  line-height: var(--h1-line-height);
  white-space: nowrap;
  font-style: var(--h1-font-style);
}

.kennedy-redo .text-wrapper-17 {
  position: relative;
  width: 852px;
  height: 225px;
  font-family: var(--body-copy-font-family);
  font-weight: var(--body-copy-font-weight);
  color: var(--variable-collection-dark-gray);
  font-size: var(--body-copy-font-size);
  text-align: center;
  letter-spacing: var(--body-copy-letter-spacing);
  line-height: var(--body-copy-line-height);
  font-style: var(--body-copy-font-style);
}

.kennedy-redo .group-22 {
  position: absolute;
  width: 935px;
  height: 572px;
  top: 292px;
  left: 169px;
}

.kennedy-redo .overlap-5 {
  position: absolute;
  width: 917px;
  height: 543px;
  top: 30px;
  left: 112px;
}

.kennedy-redo .overlap-6 {
  position: absolute;
  width: 917px;
  height: 537px;
  top: 0;
  left: 0;
}

.kennedy-redo .overlap-7 {
  position: absolute;
  width: 252px;
  height: 212px;
  top: 325px;
  left: 533px;
}

.kennedy-redo .ellipse-6 {
  position: absolute;
  width: 104px;
  height: 104px;
  top: 108px;
  left: 58px;
  background-color: var(--red);
  border-radius: 52px;
  border: 2px solid;
  border-color: var(--variable-collection-WHITE);
  box-shadow: 0px 0px 12px #00000040;
}

.kennedy-redo .frame-15 {
  display: flex;
  flex-direction: column;
  width: 69px;
  height: 56px;
  align-items: center;
  justify-content: center;
  gap: 3.28px;
  position: absolute;
  top: 94px;
  left: 78px;
  transform: rotate(-0.41deg);
}

.kennedy-redo .text-wrapper-18 {
  position: relative;
  width: 30px;
  height: 27px;
  transform: rotate(0.41deg);
  font-family: "Gotham-Bold", Helvetica;
  font-weight: 700;
  color: var(--variable-collection-WHITE);
  font-size: 12px;
  text-align: center;
  letter-spacing: 0;
  line-height: 46px;
  white-space: nowrap;
}

.kennedy-redo .overlap-wrapper {
  position: absolute;
  width: 104px;
  height: 118px;
  top: 0;
  left: 148px;
}

.kennedy-redo .overlap-8 {
  position: relative;
  height: 118px;
}

.kennedy-redo .ellipse-7 {
  position: absolute;
  width: 104px;
  height: 104px;
  top: 14px;
  left: 0;
  background-color: var(--variable-collection-other-logo-design);
  border-radius: 52px;
  border: 2px solid;
  border-color: var(--variable-collection-WHITE);
  box-shadow: 0px 0px 12px #00000040;
}

.kennedy-redo .frame-16 {
  display: flex;
  flex-direction: column;
  width: 69px;
  height: 56px;
  align-items: center;
  justify-content: center;
  gap: 3.28px;
  position: absolute;
  top: 0;
  left: 19px;
  transform: rotate(-0.41deg);
}

.kennedy-redo .group-23 {
  position: absolute;
  width: 66px;
  height: 42px;
  top: 53px;
  left: 19px;
}

.kennedy-redo .group-24 {
  position: absolute;
  width: 52px;
  height: 49px;
  top: 141px;
  left: 88px;
}

.kennedy-redo .vector {
  position: absolute;
  width: 64px;
  height: 68px;
  top: 38px;
  left: 0;
}

.kennedy-redo .path {
  position: absolute;
  width: 16px;
  height: 14px;
  top: 99px;
  left: 56px;
}

.kennedy-redo .overlap-9 {
  position: absolute;
  width: 642px;
  height: 362px;
  top: 0;
  left: 223px;
}

.kennedy-redo .group-25 {
  position: absolute;
  width: 193px;
  height: 193px;
  top: 169px;
  left: 136px;
  transform: rotate(-0.41deg);
  box-shadow: 0px 0px 12px #00000040;
}

.kennedy-redo .group-26 {
  height: 193px;
}

.kennedy-redo .overlap-10 {
  position: relative;
  width: 199px;
  height: 199px;
  top: -3px;
  left: -3px;
  border-radius: 99.46px;
}

.kennedy-redo .ellipse-8 {
  position: absolute;
  width: 199px;
  height: 199px;
  top: 0;
  left: 0;
  background-color: #ffffff;
  border-radius: 99.46px;
  border: 3px solid;
  border-color: var(--orange);
  box-shadow: 0px 0px 85.26px #00000014;
}

.kennedy-redo .group-27 {
  position: absolute;
  width: 149px;
  height: 111px;
  top: 39px;
  left: 32px;
}

.kennedy-redo .g {
  position: absolute;
  width: 117px;
  height: 67px;
  top: 0;
  left: 32px;
  transform: rotate(0.41deg);
}

.kennedy-redo .group-28 {
  position: absolute;
  width: 134px;
  height: 42px;
  top: 69px;
  left: 0;
}

.kennedy-redo .group-29 {
  position: absolute;
  width: 134px;
  height: 28px;
  top: 0;
  left: 0;
}

.kennedy-redo .overlap-group-4 {
  position: absolute;
  width: 96px;
  height: 25px;
  top: 3px;
  left: 0;
}

.kennedy-redo .path-2 {
  position: absolute;
  width: 20px;
  height: 18px;
  top: 7px;
  left: 0;
  transform: rotate(0.41deg);
}

.kennedy-redo .path-3 {
  position: absolute;
  width: 16px;
  height: 18px;
  top: 7px;
  left: 20px;
  transform: rotate(0.41deg);
}

.kennedy-redo .path-4 {
  position: absolute;
  width: 14px;
  height: 25px;
  top: 0;
  left: 35px;
  transform: rotate(0.41deg);
}

.kennedy-redo .path-5 {
  position: absolute;
  width: 11px;
  height: 18px;
  top: 7px;
  left: 49px;
  transform: rotate(0.41deg);
}

.kennedy-redo .path-6 {
  position: absolute;
  width: 14px;
  height: 25px;
  top: 0;
  left: 59px;
  transform: rotate(0.41deg);
}

.kennedy-redo .path-7 {
  position: absolute;
  width: 23px;
  height: 18px;
  top: 7px;
  left: 73px;
  transform: rotate(0.41deg);
}

.kennedy-redo .ellipse-9 {
  position: absolute;
  width: 5px;
  height: 5px;
  top: 17px;
  left: 51px;
  background-color: var(--variable-collection-other-logo-design);
  border-radius: 2.43px;
}

.kennedy-redo .overlap-11 {
  position: absolute;
  width: 38px;
  height: 28px;
  top: 0;
  left: 96px;
}

.kennedy-redo .path-8 {
  position: absolute;
  width: 21px;
  height: 28px;
  top: 0;
  left: 0;
  transform: rotate(0.41deg);
}

.kennedy-redo .path-9 {
  position: absolute;
  width: 17px;
  height: 18px;
  top: 10px;
  left: 21px;
  transform: rotate(0.41deg);
}

.kennedy-redo .group-30 {
  position: absolute;
  width: 94px;
  height: 7px;
  top: 36px;
  left: 20px;
  transform: rotate(0.41deg);
}

.kennedy-redo .group-31 {
  position: absolute;
  width: 104px;
  height: 125px;
  top: 0;
  left: 330px;
}

.kennedy-redo .overlap-12 {
  position: relative;
  height: 125px;
}

.kennedy-redo .ellipse-10 {
  position: absolute;
  width: 104px;
  height: 104px;
  top: 21px;
  left: 0;
  background-color: var(--variable-collection-other-logo-design);
  border-radius: 52px;
  border: 2px solid;
  border-color: var(--variable-collection-WHITE);
  box-shadow: 0px 0px 12px #00000040;
}

.kennedy-redo .frame-17 {
  display: flex;
  flex-direction: column;
  width: 69px;
  height: 56px;
  align-items: center;
  justify-content: center;
  gap: 3.28px;
  position: absolute;
  top: 0;
  left: 23px;
  transform: rotate(-0.41deg);
}

.kennedy-redo .vector-2 {
  position: absolute;
  width: 66px;
  height: 54px;
  top: 49px;
  left: 20px;
}

.kennedy-redo .group-32 {
  position: absolute;
  width: 131px;
  height: 131px;
  top: 56px;
  left: 511px;
}

.kennedy-redo .overlap-13 {
  position: relative;
  height: 131px;
  border-radius: 65.5px;
}

.kennedy-redo .ellipse-11 {
  position: absolute;
  width: 131px;
  height: 131px;
  top: 0;
  left: 0;
  background-color: #d9d9d9;
  border-radius: 65.5px;
}

.kennedy-redo .ellipse-12 {
  position: absolute;
  width: 128px;
  height: 128px;
  top: 1px;
  left: 0;
  background-color: var(--orange);
  border-radius: 64px;
  border: 2px solid;
  border-color: var(--variable-collection-WHITE);
  transform: rotate(-0.41deg);
  box-shadow: 0px 0px 22.86px #0000001a;
}

.kennedy-redo .group-33 {
  position: absolute;
  width: 67px;
  height: 63px;
  top: 14px;
  left: 32px;
}

.kennedy-redo .group-34 {
  position: absolute;
  width: 108px;
  height: 108px;
  top: 29px;
  left: 0;
}

.kennedy-redo .overlap-14 {
  position: relative;
  width: 112px;
  height: 112px;
  top: -2px;
  left: -2px;
  border-radius: 56px;
}

.kennedy-redo .ellipse-13 {
  position: absolute;
  width: 112px;
  height: 112px;
  top: 0;
  left: 0;
  background-color: var(--red);
  border-radius: 56px;
  border: 2px solid;
  border-color: var(--variable-collection-WHITE);
  box-shadow: 0px 0px 12px #00000040;
}

.kennedy-redo .group-35 {
  position: absolute;
  width: 55px;
  height: 75px;
  top: 18px;
  left: 29px;
}

.kennedy-redo .vector-3 {
  position: absolute;
  width: 35px;
  height: 42px;
  top: 127px;
  left: 304px;
}

.kennedy-redo .vector-4 {
  position: absolute;
  width: 177px;
  height: 78px;
  top: 164px;
  left: 325px;
}

.kennedy-redo .vector-5 {
  position: absolute;
  width: 49px;
  height: 45px;
  top: 133px;
  left: 105px;
}

.kennedy-redo .text-wrapper-19 {
  position: absolute;
  width: 104px;
  top: 140px;
  left: 525px;
  transform: rotate(-0.41deg);
  font-family: "Gotham-Book", Helvetica;
  font-weight: 400;
  color: var(--variable-collection-WHITE);
  font-size: 16px;
  text-align: center;
  letter-spacing: 0;
  line-height: 20px;
}

.kennedy-redo .path-10 {
  position: absolute;
  width: 14px;
  height: 16px;
  top: 119px;
  left: 331px;
}

.kennedy-redo .path-11 {
  position: absolute;
  width: 17px;
  height: 14px;
  top: 126px;
  left: 97px;
}

.kennedy-redo .path-12 {
  position: absolute;
  width: 28px;
  height: 27px;
  top: 152px;
  left: 488px;
}

.kennedy-redo .overlap-15 {
  position: absolute;
  width: 229px;
  height: 222px;
  top: 224px;
  left: 0;
}

.kennedy-redo .group-36 {
  position: absolute;
  width: 131px;
  height: 131px;
  top: 0;
  left: 0;
}

.kennedy-redo .ellipse-14 {
  position: absolute;
  width: 131px;
  height: 131px;
  top: 0;
  left: 0;
  background-color: #f88d2a;
  border-radius: 65.5px;
  border: 2px solid;
  border-color: #ffffff;
  box-shadow: 0px 0px 12px #00000040;
}

.kennedy-redo .frame-18 {
  position: absolute;
  width: 69px;
  height: 56px;
  top: 73px;
  left: 30px;
  transform: rotate(-0.41deg);
}

.kennedy-redo .text-wrapper-20 {
  position: absolute;
  top: 4px;
  left: 8px;
  transform: rotate(0.41deg);
  font-family: "Gotham-Book", Helvetica;
  font-weight: 400;
  color: var(--variable-collection-WHITE);
  font-size: 28px;
  text-align: center;
  letter-spacing: 0;
  line-height: 46px;
  white-space: nowrap;
}

.kennedy-redo .group-37 {
  position: absolute;
  width: 46px;
  height: 57px;
  top: 23px;
  left: 44px;
}

.kennedy-redo .group-38 {
  position: absolute;
  width: 108px;
  height: 108px;
  top: 114px;
  left: 121px;
}

.kennedy-redo .ellipse-15 {
  position: absolute;
  width: 112px;
  height: 112px;
  top: 0;
  left: 0;
  background-color: var(--variable-collection-other-logo-design);
  border-radius: 56px;
  border: 2px solid;
  border-color: var(--variable-collection-WHITE);
  box-shadow: 0px 0px 12px #00000040;
}

.kennedy-redo .group-39 {
  position: absolute;
  width: 62px;
  height: 72px;
  top: 20px;
  left: 25px;
}

.kennedy-redo .group-40 {
  position: absolute;
  width: 104px;
  height: 125px;
  top: 206px;
  left: 813px;
}

.kennedy-redo .group-41 {
  position: absolute;
  width: 67px;
  height: 50px;
  top: 50px;
  left: 20px;
}

.kennedy-redo .overlap-16 {
  position: absolute;
  width: 114px;
  height: 48px;
  top: 320px;
  left: 567px;
}

.kennedy-redo .vector-6 {
  position: absolute;
  width: 103px;
  height: 42px;
  top: 0;
  left: 0;
}

.kennedy-redo .path-13 {
  position: absolute;
  width: 17px;
  height: 14px;
  top: 34px;
  left: 96px;
}

.kennedy-redo .overlap-17 {
  position: absolute;
  width: 235px;
  height: 15px;
  top: 275px;
  left: 571px;
}

.kennedy-redo .vector-7 {
  position: absolute;
  width: 224px;
  height: 3px;
  top: 6px;
  left: 0;
}

.kennedy-redo .path-14 {
  position: absolute;
  width: 16px;
  height: 15px;
  top: 0;
  left: 218px;
}

.kennedy-redo .overlap-18 {
  position: absolute;
  width: 113px;
  height: 62px;
  top: 304px;
  left: 230px;
}

.kennedy-redo .vector-8 {
  position: absolute;
  width: 102px;
  height: 53px;
  top: 0;
  left: 11px;
}

.kennedy-redo .path-15 {
  position: absolute;
  width: 16px;
  height: 15px;
  top: 47px;
  left: 0;
}

.kennedy-redo .overlap-19 {
  position: absolute;
  width: 224px;
  height: 26px;
  top: 261px;
  left: 134px;
}

.kennedy-redo .vector-9 {
  position: absolute;
  width: 207px;
  height: 12px;
  top: 4px;
  left: 17px;
}

.kennedy-redo .path-16 {
  position: absolute;
  width: 28px;
  height: 26px;
  top: 0;
  left: 0;
}

.kennedy-redo .overlap-20 {
  position: absolute;
  width: 151px;
  height: 45px;
  top: 174px;
  left: 198px;
}

.kennedy-redo .vector-10 {
  position: absolute;
  width: 140px;
  height: 38px;
  top: 6px;
  left: 11px;
}

.kennedy-redo .path-17 {
  position: absolute;
  width: 17px;
  height: 14px;
  top: 0;
  left: 0;
}

.kennedy-redo .group-42 {
  position: absolute;
  width: 108px;
  height: 108px;
  top: 435px;
  left: 283px;
}

.kennedy-redo .group-43 {
  position: absolute;
  width: 65px;
  height: 53px;
  top: 33px;
  left: 24px;
}

.kennedy-redo .group-44 {
  position: absolute;
  width: 108px;
  height: 108px;
  top: 107px;
  left: 83px;
}

.kennedy-redo .group-45 {
  position: absolute;
  width: 58px;
  height: 70px;
  top: 22px;
  left: 30px;
}

.kennedy-redo .overlap-21 {
  position: absolute;
  width: 34px;
  height: 63px;
  top: 369px;
  left: 364px;
}

.kennedy-redo .vector-11 {
  position: absolute;
  width: 27px;
  height: 53px;
  top: 0;
  left: 7px;
}

.kennedy-redo .path-18 {
  position: absolute;
  width: 14px;
  height: 17px;
  top: 46px;
  left: 0;
}

.kennedy-redo .local-international {
  position: absolute;
  top: 0;
  left: 531px;
  font-family: "Gotham-Medium", Helvetica;
  font-weight: 500;
  color: var(--variable-collection-other-logo-design);
  font-size: 24px;
  text-align: center;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
}

.kennedy-redo .individual-corporate {
  position: absolute;
  top: 0;
  left: 0;
  font-family: "Gotham-Medium", Helvetica;
  font-weight: 500;
  color: var(--variable-collection-other-logo-design);
  font-size: 24px;
  text-align: center;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
}

.kennedy-redo .why-should-you {
  position: absolute;
  width: 605px;
  top: 3664px;
  left: 740px;
  font-family: "Century Schoolbook";
  font-weight: var(--h1-font-weight);
  color: transparent;
  font-size: var(--h1-font-size);
  letter-spacing: var(--h1-letter-spacing);
  line-height: var(--h1-line-height);
  white-space: nowrap;
  font-style: var(--h1-font-style);
}

.volunteerCls{
  margin-left: -15px;
}

.kennedy-redo .overlap-22 {
  position: absolute;
  width: 674px;
  height: 418px;
  top: 3789px;
  left: 775px;
}

.kennedy-redo .text-wrapper-21 {
  position: absolute;
  width: 508px;
  top: 5px;
  left: -35px;
  font-family: "Gotham-Medium", Helvetica;
  font-weight: 500;
  color: var(--variable-collection-dark-gray);
  font-size: 24px;
  text-align: center;
  letter-spacing: 0;
  line-height: 46px;
  white-space: nowrap;
}

.kennedy-redo .flexcontainer-2 {
  width: 674px;
  height: 388px;
  top: 30px;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 11px;
  position: absolute;
}

.kennedy-redo .text-2 {
  position: relative;
  align-self: stretch;
  font-family: "Gotham-Regular", Helvetica;
  font-weight: 400;
  color: var(--variable-collection-other-logo-design);
  font-size: 24px;
  letter-spacing: 0;
  line-height: 30px;
  list-style:disc outside none;
  display:list-item; 
}

.kennedy-redo .text-wrapper-22 {
  font-family: var(--body-copy-font-family);
  font-weight: var(--body-copy-font-weight);
  color: #535050;
  font-size: var(--body-copy-font-size);
  letter-spacing: var(--body-copy-letter-spacing);
  line-height: var(--body-copy-line-height);
  font-style: var(--body-copy-font-style);
  
}

.kennedy-redo .text-wrapper-23 {
  font-family: "Gotham-Bold", Helvetica;
  font-weight: 700;
}

.kennedy-redo .text-wrapper-24 {
  font-family: "Gotham-Regular", Helvetica;
  font-weight: 400;
  color: #535050;
  font-size: 24px;
  letter-spacing: 0;
  line-height: 30px;
}

.kennedy-redo .frame-19 {
  display: flex;
  width: 397px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 15px 50px;
  position: absolute;
  top: 420px;
  left: 5px;
  background-color: var(--red);
  border-radius: 12px;
  overflow: hidden;
  border: 1px solid;
  border-color: var(--variable-collection-WHITE);
  box-shadow: 0px 4px 4px #00000040;
}

.kennedy-redo .rectangle-6 {
  position: absolute;
  width: 187px;
  height: 55px;
  top: 6918px;
  left: 4987px;
}

.kennedy-redo .frame-20 {
  display: flex;
  flex-direction: column;
  width: 840px;
  height: 135px;
  align-items: center;
  gap: 30px;
  position: absolute;
  top: 981px;
  left: 381px;
}

.kennedy-redo .what-causes-are-you {
  position: relative;
  width: 913px;
  margin-top: -1px;
  margin-left: -36.5px;
  margin-right: -36.5px;
  font-family: "Century Schoolbook";
  font-weight: var(--h1-font-weight);
  color: transparent;
  font-size: var(--h1-font-size);
  text-align: center;
  letter-spacing: var(--h1-letter-spacing);
  line-height: var(--h1-line-height);
  font-style: var(--h1-font-style);
}

.kennedy-redo .text-wrapper-25 {
  position: relative;
  width: fit-content;
  margin-bottom: -61px;
  font-family: var(--h4-font-family);
  font-weight: var(--h4-font-weight);
  color: var(--variable-collection-dark-gray);
  font-size: var(--h4-font-size);
  letter-spacing: var(--h4-letter-spacing);
  line-height: var(--h4-line-height);
  white-space: nowrap;
  font-style: var(--h4-font-style);
}

.kennedy-redo .group-46 {
  position: absolute;
  width: 1390px;
  height: 450px;
  top: 1230px;
  left: 105px;
}

.kennedy-redo .frame-21 {
  display: flex;
  width: 309px;
  height: 424px;
  align-items: flex-start;
  gap: 10px;
  position: absolute;
  top: 0;
  left: 56px;
}

.kennedy-redo .rectangle-7 {
  position: relative;
  width: 310px;
  height: 426px;
  margin-bottom: -2px;
  margin-right: -1px;
  background-color: #ffffff99;
  border-radius: 12px;
  border: 0.5px solid;
  border-color: #5f504d80;
  box-shadow: 0px 4px 45px #0000000d;
}

.kennedy-redo .frame-22 {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
  position: absolute;
  top: 46px;
  left: 9px;
}

.kennedy-redo .icons {
  position: relative;
  width: 65px;
  height: 65px;
}

.kennedy-redo .frame-23 {
  display: inline-flex;
  flex-direction: column;
  height: 219px;
  align-items: center;
  gap: 30px;
  position: relative;
}

.kennedy-redo .text-wrapper-26 {
  position: relative;
  width: 288px;
  margin-top: -1px;
  font-family: "Century-Regular", Helvetica;
  font-weight: 400;
  color: var(--variable-collection-other-logo-design);
  font-size: 29px;
  text-align: center;
  letter-spacing: 0;
  line-height: 34px;
}

.kennedy-redo .terrorism-is-THE-USE {
  position: relative;
  width: 267px;
  font-family: "Gotham-Light", Helvetica;
  font-weight: 300;
  color: var(--variable-collection-other-logo-design);
  font-size: 16px;
  text-align: center;
  letter-spacing: 0;
  line-height: 22px;
}

.kennedy-redo .text-wrapper-27 {
  font-family: "Gotham-Light", Helvetica;
  font-weight: 300;
  color: #535050;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
}

.kennedy-redo .frame-24 {
  width: 308px;
  left: 380px;
  display: flex;
  height: 424px;
  align-items: flex-start;
  gap: 10px;
  position: absolute;
  top: 0;
}

.kennedy-redo .rectangle-8 {
  position: relative;
  width: 310px;
  height: 426px;
  margin-bottom: -2px;
  margin-right: -2px;
  background-color: #ffffff99;
  border-radius: 12px;
  border: 0.5px solid;
  border-color: #5f504d80;
  box-shadow: 0px 4px 45px #0000000d;
}

.kennedy-redo .frame-25 {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
  position: absolute;
  top: 46px;
  left: 8px;
}

.kennedy-redo .terrorism {
  position: relative;
  width: 288px;
  margin-top: -1px;
  font-family: "Century-Regular", Helvetica;
  font-weight: 400;
  color: var(--variable-collection-other-logo-design);
  font-size: 29px;
  text-align: center;
  letter-spacing: 0;
  line-height: 39px;
}

.kennedy-redo .frame-26 {
  width: 309px;
  left: 703px;
  display: flex;
  height: 424px;
  align-items: flex-start;
  gap: 10px;
  position: absolute;
  top: 0;
}

.kennedy-redo .icons-2 {
  position: relative;
  width: 67.6px;
  height: 65px;
}

.kennedy-redo .terrorism-is-THE-USE-2 {
  position: relative;
  width: 267px;
  margin-bottom: -10px;
  font-family: "Gotham-Light", Helvetica;
  font-weight: 300;
  color: var(--variable-collection-other-logo-design);
  font-size: 16px;
  text-align: center;
  letter-spacing: 0;
  line-height: 22px;
}

.kennedy-redo .frame-27 {
  width: 309px;
  left: 1026px;
  display: flex;
  height: 424px;
  align-items: flex-start;
  gap: 10px;
  position: absolute;
  top: 0;
}

.kennedy-redo .overlap-23 {
  position: absolute;
  width: 31px;
  height: 31px;
  top: 202px;
  left: -1px;
  /* background-image: url(./img/vector-6.svg); */
  background-size: 100% 100%;
}

.kennedy-redo .vector-12 {
  position: absolute;
  width: 8px;
  height: 14px;
  top: 9px;
  left: 9px;
}

.kennedy-redo .vector-13 {
  position: absolute;
  width: 14px;
  height: 2px;
  top: 15px;
  left: 9px;
}

.kennedy-redo .overlap-24 {
  position: absolute;
  width: 31px;
  height: 31px;
  top: 202px;
  left: 1360px;
  /* background-image: url(./img/vector-3.svg); */
  background-size: 100% 100%;
}

.kennedy-redo .vector-14 {
  position: absolute;
  width: 8px;
  height: 14px;
  top: 9px;
  left: 15px;
}

.kennedy-redo .rectangle-9 {
  position: absolute;
  width: 696px;
  height: 787px;
  top: 1730px;
  left: -1956px;
  background-color: #c10230;
}

.kennedy-redo .overlap-25 {
  position: absolute;
  width: 1600px;
  height: 714px;
  top: 6241px;
  left: 0;
}

.kennedy-redo .group-47 {
  position: absolute;
  width: 1600px;
  height: 714px;
  top: 0;
  left: 0;
  background-color: #ededed;
}

.kennedy-redo .group-48 {
  position: relative;
  width: 1350px;
  height: 556px;
  top: 50px;
  left: 158px;
}

.kennedy-redo .group-49 {
  position: absolute;
  width: 161px;
  height: 43px;
  top: 513px;
  left: 1189px;
}

.kennedy-redo .element-actitude-wrapper {
  position: relative;
  width: 163px;
  height: 43px;
  opacity: 0.7;
}

.kennedy-redo .element-actitude {
  position: absolute;
  width: 161px;
  top: 0;
  left: 0;
  font-family: "Gotham-Book", Helvetica;
  font-weight: 400;
  color: var(--variable-collection-other-logo-design);
  font-size: 14px;
  text-align: right;
  letter-spacing: 0;
  line-height: 36px;
  white-space: nowrap;
}

.kennedy-redo .group-50 {
  position: absolute;
  width: 1299px;
  height: 389px;
  top: 0;
  left: 0;
}

.kennedy-redo .frame-28 {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 35px;
  position: relative;
}

.kennedy-redo .text-wrapper-28 {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family:"Century Schoolbook";
  font-weight: 700;
  color: #000000;
  font-size: 44px;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
}

.kennedy-redo .text-wrapper-29 {
  position: relative;
  width: 1299px;
  height: 89px;
  font-family: "Gotham-Book", Helvetica;
  font-weight: 400;
  color: var(--variable-collection-other-logo-design);
  font-size: 20px;
  letter-spacing: 0;
  line-height: 33px;
}

.kennedy-redo .line-3 {
  position: relative;
  width: 1296px;
  height: 4.94px;
}

.kennedy-redo .group-51 {
  position: relative;
  width: 1225px;
  height: 139.06px;
}

.kennedy-redo .group-52 {
  position: absolute;
  width: 251px;
  height: 139px;
  top: 0;
  left: 980px;
}

.kennedy-redo .text-wrapper-30 {
  position: absolute;
  width: 232px;
  height: 20px;
  top: 39px;
  left: 0;
  font-family: "Gotham-Book", Helvetica;
  font-weight: 400;
  color: var(--variable-collection-other-logo-design);
  font-size: 20px;
  letter-spacing: 0;
  line-height: 42px;
  white-space: nowrap;
}

.kennedy-redo .text-wrapper-31 {
  position: absolute;
  width: 127px;
  height: 20px;
  top: 79px;
  left: 0;
  font-family: "Gotham-Book", Helvetica;
  font-weight: 400;
  color: var(--variable-collection-other-logo-design);
  font-size: 20px;
  letter-spacing: 0;
  line-height: 42px;
  white-space: nowrap;
}

.kennedy-redo .text-wrapper-32 {
  width: 127px;
  height: 20px;
  top: 119px;
  color: var(--variable-collection-other-logo-design);
  line-height: 42px;
  position: absolute;
  left: 0;
  font-family: "Gotham-Book", Helvetica;
  font-weight: 400;
  font-size: 20px;
  letter-spacing: 0;
  white-space: nowrap;
}

.kennedy-redo .group-53 {
  position: absolute;
  width: 247px;
  height: 19px;
  top: 0;
  left: 0;
}

.kennedy-redo .text-wrapper-33 {
  position: absolute;
  width: 245px;
  height: 19px;
  top: 0;
  left: 0;
  font-family: "Gotham-Book", Helvetica;
  font-weight: 400;
  color: var(--variable-collection-other-logo-design);
  font-size: 20px;
  letter-spacing: 0;
  line-height: 42px;
  white-space: nowrap;
}

.kennedy-redo .group-54 {
  position: absolute;
  width: 250px;
  height: 92px;
  top: 4px;
  left: 650px;
}

.kennedy-redo .text-wrapper-34 {
  position: absolute;
  width: 240px;
  height: 16px;
  top: 0;
  left: 0;
  font-family: "Gotham-Book", Helvetica;
  font-weight: 400;
  color: var(--variable-collection-other-logo-design);
  font-size: 20px;
  letter-spacing: 0;
  line-height: 42px;
  white-space: nowrap;
}

.kennedy-redo .text-wrapper-35 {
  position: absolute;
  width: 244px;
  height: 18px;
  top: 36px;
  left: 0;
  font-family: "Gotham-Book", Helvetica;
  font-weight: 400;
  color: var(--variable-collection-other-logo-design);
  font-size: 20px;
  letter-spacing: 0;
  line-height: 42px;
  white-space: nowrap;
}

.kennedy-redo .text-wrapper-36 {
  position: absolute;
  width: 203px;
  height: 18px;
  top: 74px;
  left: 0;
  font-family: "Gotham-Book", Helvetica;
  font-weight: 400;
  color: var(--variable-collection-other-logo-design);
  font-size: 20px;
  letter-spacing: 0;
  line-height: 42px;
  white-space: nowrap;
}

.kennedy-redo .horizontal-logo-2 {
  position: absolute;
  width: 385px;
  height: 136px;
  top: 0;
  left: 0;
}

.kennedy-redo .footer {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  position: absolute;
  top: 696px;
  left: 0;
  background-color: transparent;
}

.kennedy-redo .rectangle-10 {
  position: relative;
  width: 1600px;
  height: 18px;
  background-color: var(--red);
}

.kennedy-redo .group-55 {
  position: absolute;
  width: 617px;
  height: 316px;
  top: 5819px;
  left: 500px;
}

.kennedy-redo .overlap-26 {
  position: absolute;
  width: 611px;
  height: 74px;
  top: 164px;
  left: 0;
}

.kennedy-redo .text-wrapper-37 {
  position: absolute;
  top: 26px;
  left: 525px;
  font-family: var(--button-text-font-family);
  font-weight: var(--button-text-font-weight);
  color: var(--variable-collection-WHITE);
  font-size: var(--button-text-font-size);
  letter-spacing: var(--button-text-letter-spacing);
  line-height: var(--button-text-line-height);
  white-space: nowrap;
  font-style: var(--button-text-font-style);
}

.kennedy-redo .group-56 {
  position: absolute;
  width: 605px;
  height: 33px;
  top: 0;
  left: 0;
}

.kennedy-redo .input-fields {
  position: absolute;
  width: 483px;
  height: 33px;
  top: 0;
  left: 120px;
}

.kennedy-redo .overlap-group-5 {
  position: relative;
  height: 54px;
}

.kennedy-redo .error-message {
  position: absolute;
  top: 12px;
  left: 0;
  opacity: 0.8;
  font-family: "Montserrat", Helvetica;
  font-weight: 400;
  color: #e61010;
  font-size: 12px;
  letter-spacing: 0;
  line-height: normal;
}

.kennedy-redo .box {
  position: absolute;
  width: 483px;
  height: 33px;
  top: 0;
  left: 0;
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid;
  border-color: #d4d7de;
}

.kennedy-redo .key-field {
  position: absolute;
  top: 10px;
  left: 16px;
  opacity: 0.8;
  font-family: "Montserrat", Helvetica;
  font-weight: 400;
  color: #323940;
  font-size: 16px;
  letter-spacing: 0;
  line-height: normal;
}

.kennedy-redo .value-main {
  height: 28px;
  position: absolute;
  top: 26px;
  left: 17px;
  display: inline-flex;
  align-items: center;
}

.kennedy-redo .start-cursor {
  position: relative;
  width: 1px;
  height: 18px;
  margin-top: -3362.35px;
  margin-right: -3218px;
}

.kennedy-redo .value-masked {
  justify-content: flex-end;
  position: relative;
  flex: 0 0 auto;
  transform: rotate(180deg);
  display: inline-flex;
  align-items: center;
}

.kennedy-redo .mask-layer {
  position: relative;
  width: 57px;
  height: 20px;
  transform: rotate(-180deg);
}

.kennedy-redo .end-cursor {
  position: relative;
  width: 1px;
  height: 18px;
  margin-top: -3362.35px;
  margin-right: -3275px;
}

.kennedy-redo .text-wrapper-38 {
  width: 67px;
  top: 7px;
  color: #000000;
  line-height: normal;
  position: absolute;
  left: 0;
  font-family: "Gotham-Book", Helvetica;
  font-weight: 400;
  font-size: 20px;
  letter-spacing: 0;
  white-space: nowrap;
}

.kennedy-redo .group-57 {
  position: absolute;
  width: 605px;
  height: 33px;
  top: 40px;
  left: 0;
}

.kennedy-redo .start-cursor-2 {
  margin-top: -3402.53px;
  position: relative;
  width: 1px;
  height: 18px;
  margin-right: -3218px;
}

.kennedy-redo .end-cursor-2 {
  margin-top: -3402.53px;
  position: relative;
  width: 1px;
  height: 18px;
  margin-right: -3275px;
}

.kennedy-redo .text-wrapper-39 {
  position: absolute;
  width: 63px;
  top: 10px;
  left: 0;
  font-family: "Gotham-Book", Helvetica;
  font-weight: 400;
  color: #000000;
  font-size: 20px;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
}

.kennedy-redo .group-58 {
  position: absolute;
  width: 605px;
  height: 33px;
  top: 124px;
  left: 0;
}

.kennedy-redo .text-wrapper-40 {
  position: absolute;
  width: 112px;
  top: 6px;
  left: 0;
  font-family: "Gotham-Book", Helvetica;
  font-weight: 400;
  color: #000000;
  font-size: 20px;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
}

.kennedy-redo .start-cursor-3 {
  margin-top: -3322.17px;
  position: relative;
  width: 1px;
  height: 18px;
  margin-right: -3218px;
}

.kennedy-redo .end-cursor-3 {
  margin-top: -3322.17px;
  position: relative;
  width: 1px;
  height: 18px;
  margin-right: -3275px;
}

.kennedy-redo .text-wrapper-41 {
  position: absolute;
  width: 446px;
  top: 0;
  left: 79px;
  font-family: "Century Schoolbook";
  font-weight: 700;
  color: #000000;
  font-size: 44px;
  text-align: center;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
}

.kennedy-redo .text-wrapper-42 {
  position: absolute;
  width: 320px;
  height: 22px;
  top: 55px;
  left: 142px;
  font-family: "Gotham-Book", Helvetica;
  font-weight: 400;
  color: var(--variable-collection-other-logo-design);
  font-size: 20px;
  text-align: center;
  letter-spacing: 0;
  line-height: 30px;
  white-space: nowrap;
}

.kennedy-redo .frame-29 {
  width: 259px;
  top: 259px;
  left: 215px;
  box-shadow: 0px 4px 4px #00000040;
  display: flex;
  height: 57px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 15px 50px;
  position: absolute;
  background-color: var(--red);
  border-radius: 10px;
  overflow: hidden;
}

.kennedy-redo .text-wrapper-43 {
  position: relative;
  width: fit-content;
  margin-top: -5px;
  margin-bottom: -3px;
  font-family: var(--button-text-font-family);
  font-weight: var(--button-text-font-weight);
  color: var(--variable-collection-WHITE);
  font-size: var(--button-text-font-size);
  letter-spacing: var(--button-text-letter-spacing);
  line-height: var(--button-text-line-height);
  white-space: nowrap;
  font-style: var(--button-text-font-style);
}

.kennedy-redo .OBJECTS {
  position: absolute;
  width: 630px;
  height: 635px;
  top: 3674px;
  left: 0;
}
