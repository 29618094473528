.KnowMoreMain h3{
    font-size: 44px;
    line-height: 44px;
}

.KnowMoreMain .subtitle{
    color: #535050;
    font-size: 20px;
    font-family: "Gotham-Book";
    text-shadow: 0px 0px;
    padding: 0px;
}

.KnowMoreMain label{
    color: black;
    font-size: 20px;
    font-family: "Gotham-Book";
    font-weight: 325;
    word-wrap: break-word;
    width: 112px;
}

.KnowMoreMain input{
    border: 1px #D5D8DE solid;
    border-radius: 4px;
    height: 34px;
    margin-bottom: 4px;
    padding: 0 16px;
    color: #323941;
    font-size: 14px;
    font-family: "Gotham-Book";
    font-weight: 400;
    word-wrap: break-word
}

.KnowMoreMain button{
    color: white;
    font-size: 20px;
    font-family: "Gotham-Medium";
    font-weight: 700;
    text-transform: uppercase;
    line-height: 35px;
    word-wrap: break-word;
    padding-left: 50px; 
    padding-right: 50px;
    background: #C10230; 
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); 
    border-radius: 10px;
    justify-content: center; 
    align-items: center; 
    border: 0px;
    width: 260px;
    height: 57px;
    margin-top: 20px;
}