section.BurningQuestionMain{
    padding: 94px 0px;
    background: url("../../../assets/images/Banner/heart-section.png") no-repeat;
    background-size: cover;
    background-position: center;
}


.BurningQuestionMain h3{
    color: black;
    font-size: 54px;
    font-family: "CenturyBoldSWFTE";
    font-weight: 700;
    line-height: 66px;
    word-wrap: break-word;
    text-align: left;
}

.BurningQuestionMain .subtitle{
    color: #5F504D;
    font-size: 28px;
    font-family: "Gotham-Medium";
    font-weight: 500;
    line-height: 35px;
    word-wrap: break-word;
    padding-top: 20px;
    margin-bottom: 52px;
    text-align: left;
}

.burningQuestion .burningQuestionList{
    color: #535050;
    font-size: 28px;
    font-family: "Gotham-Medium";
    font-weight: 500;
    line-height: 35px;
    word-wrap: break-word;
    margin-bottom: 12px;
}

.burningQuestion .burningQuestionList:last-child{
    margin-bottom: 0px;
}

.burningQuestion .burningQuestionList span{
    color: #535050;
    font-size: 28px;
    font-family: "Gotham-Bold";
    font-weight: 700;
    line-height: 35px;
    word-wrap: break-word;
    font-style: italic;
}

.burningQuestionForm {
    background: white;
    border-radius: 12px;
    text-align: center;
    padding-top: 26px;
    padding-bottom: 35px;
    padding-left: 60px;
    padding-right: 60px;
    box-shadow: 1px 8px 5px #00000040;
}

.burningQuestionForm p{
    color: #535050;
    font-size: 24px;
    font-family: "Gotham-Light";
    font-weight: 325;
    line-height: 30px;
    word-wrap: break-word;
    text-align: center;
    margin-bottom: 17px;
}

.defaultBtnStyle{
    color: white;
    font-size: 18px;
    font-family: "Gotham-Medium";
    font-weight: 700;
    text-transform: capitalize;
    word-wrap: break-word;
    background: #C10230;
    padding: 18px 38px;
    border: 0px;
    border-radius: 12px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); 
}