.OurVisionMainInner{
    background: url("../../../assets/images/Banner/white-heart-background.png");
    background-color: var(--orange);
    border-radius: 20px;
    box-shadow: 0px 0px 12px #00000040;
    height: 438px;
    display: flex;
    align-items: center;
}

.OurVisionLeft p{
    color: white;
    font-size: 28px;
    font-family: "Gotham-Medium";
    font-weight: 500;
    line-height: 42px;
    word-wrap: break-word;
    font-style: italic;
}

.OurVisionLeftInner{
    padding: 64px 10%;
    padding-bottom: 0px;
    /* padding-top: 0px; */
}

.ownerName{
    display: flex;
    align-items: center;
    margin-top: 20px;
    justify-content: end;
    margin-right: 1%;
}

.ownerName span:nth-child(2){
    color: white;
    font-size: 28.05px;
    font-family: "Gotham-Book";
    font-weight: 400;
    line-height: 54.70px;
    word-wrap: break-word;
    padding: 0 14px;
}

.ownerName span:nth-child(1),
.ownerName span:nth-child(3){
    width: 28px;
    height: 1px;
    background-color: white;
    display: block;
}


.OurVisionRight .burningQuestionForm{
    width: 414px;
    height: 318px;
    /* margin-top: 30px; */
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.OurVisionRight .burningQuestionForm h5{
    color: black;
    font-size: 44px;
    font-family: Century;
    font-weight: 700;
    word-wrap: break-word;
    margin-bottom: 18px;
}

.OurVisionRight {
    display: flex;
    align-items: center;
    justify-content: end;
    padding-right: 96px;
}


.OurVisionRight .defaultBtnStyle{
    padding: 20px 50px;
    font-size: 20px;
    box-shadow: none;
    margin-top: 10px;
}