.MeetVolunteersMain{

}

.MeetVolunteersMain p{
    color: #5F504D;
    font-size: 24px;
    font-family: "Gotham-Book";
    font-weight: 400;
    line-height: 30px;
    word-wrap: break-word;
    margin-bottom: 80px;
}

/* .MeetVolunteersMain .actitude-volunteers {
    position: absolute;
    width: 334px;
    height: 365px;
    top: 231px;
    left: 81px;
  } */
  
  .MeetVolunteersMain .frame-10 {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
    position: relative;
    top: 4px;
    left: 40px;
  }
  
  .MeetVolunteersMain .ellipse-5 {
    position: relative;
    width: 225px;
    height: 225px;
    object-fit: cover;
  }
  
  .MeetVolunteersMain .frame-11 {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    gap: 7px;
    position: relative;
    flex: 0 0 auto;
  }
  
  .MeetVolunteersMain .frame-12 {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    position: relative;
    flex: 0 0 auto;
  }
  
  .MeetVolunteersMain .text-wrapper-16 {
    position: relative;
    width: fit-content;
    margin-top: -1px;
    font-family: "CenturyBoldSWFTE";
    font-weight: 700;
    color: var(--variable-collection-other-logo-design);
    font-size: 30px;
    text-align: center;
    letter-spacing: 0;
    line-height: 34px;
    white-space: nowrap;
  }
  
  .MeetVolunteersMain .line-2 {
    position: relative;
    width: 94px;
    height: 1px;
    object-fit: cover;
  }

  .MeetVolunteersMain .text-wrapper-16:after {
    content: " ";
    background: orange;
    display: block;
    height: 2px;
    margin-top: 20px;
    width: 100px;
  }

  .MeetVolunteersMain .caring-for-homeless{
    color: #535050;
    font-size: 18px;
    font-family: "Gotham-Light";
    text-align: center;
    font-weight: 300;
    text-transform: capitalize;
    line-height: 26px;
    word-wrap: break-word
  }