.VolunteerMain{
    text-align: left;
}

.VolunteerMain h3{
    text-align: left;
}

.VolunteerRight p{
    color: #5F504D;
    font-size: 24px;
    font-family: "Gotham-Medium";
    font-weight: 500;
    line-height: 46px;
    word-wrap: break-word;
    margin-top: 14px;
    margin-bottom: 30px;
}

.VolunteerRight .burningQuestion{
    list-style: disc;
    padding-left: 28px;
}

.VolunteerRight  .burningQuestion .burningQuestionList,
.VolunteerRight  .burningQuestion .burningQuestionList span{
    font-size: 24px;
    font-style: normal;
}

.VolunteerRight  .burningQuestion .burningQuestionList{
    margin-bottom: 0px;
    font-family: "Gotham-Book";
}