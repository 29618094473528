@import "../../../assets/css/global.css";


.carousel .carousel-inner .carousel-caption {
    width: 60%;
    position: absolute;
    left: 223px;
    top: 136px;
    text-align: left;
}

.carousel .carousel-inner .carousel-caption p{
    color: white;
    font-size: 32px;
    font-family: "Gotham-Book";
    font-weight: 400;
    line-height: 42px;
    word-wrap: break-word;
    text-align: left;
}

.carousel .carousel-inner .carousel-caption h5{
    color: white;
    font-size: 54px;
    font-family: "CenturyBoldSWFTE";
    font-weight: 700;
    line-height: 66px;
    word-wrap: break-word;
    text-align: left;
    letter-spacing: 0px;
}
.carousel .carousel-inner .carousel-caption-bottom{
    position: relative;
    top: 60px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.carousel .carousel-inner .carousel-caption small{
    color: white;
    font-size: 24px;
    font-family: "Gotham-Light";
    font-weight: 325;
    line-height: 30px;
    word-wrap: break-word;
    text-align: left;
}

.carousel .carousel-inner .carousel-caption-bottom .defaultBtnStyle{
    padding: 15px 50px;
    background: #C10230; 
    border-radius: 10px; 
    justify-content: center; 
    align-items: center; 
    gap: 10px; 
    display: "inline-flex";
    border: 0px;
    margin-top: 15px;
    color: white;
    font-size: 18px;
    font-family: "Gotham-Medium";
    font-weight: 700;
    text-transform: capitalize;
    line-height: 35px;
    word-wrap: break-word
}


.carousel .carousel-indicators button.active{
    background-color: white !important;
}

.carousel .carousel-indicators button{
    width: 20px;
    height: 0px !important;
    background: rgba(255, 255, 255, 0.40) !important;
    border-radius: 50px;
}