.login {
    /* width: 400px; */
    display: flex;
    align-items: center;
    justify-content: center;
    /* height: 30vw; */
    margin-top: 10vw !important;
    background: #fff;
    /* border: 1px solid #dddfe2; */
    /* box-shadow: 0 2px 4px rgb(0 0 10 / 64%), 0 8px 16px rgb(0 10 0 / 34%); */
    border-radius: 8px;
    padding: 1rem;
    align-items: center;
    text-align: center;
}

.login > form input {
    border-radius: 20px;
    border: 2px solid lightgrey;
    outline: none;
    color: #1d2129;
    margin: 2% 0;
    width: 90%;
    padding: 12px;
    font-size: 16px;
}