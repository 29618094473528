.FooterMain{
    background-color: #EDEDED;
    padding-top: 50px;
    padding-bottom: 50px;
    position: relative;
}


.FooterMain::after{
    content: " ";
    height: 18px;
    background-color: #C10230;
    display: block;
    position: absolute;
    bottom: 0px;
    width: 100%;
}

.FooterMain h3{
    font-size: 44px;
    text-align: left;
    margin-bottom: 35px;
}

.FooterMain .para{
    color: #535050; 
    font-size: 20px;
    font-style: normal; 
    font-family: "Gotham-Book"; 
    font-weight: 400; 
    line-height: 33px; 
    word-wrap: break-word
}

.FooterMain .nav li{
    margin-bottom: 20px !important;
}

.FooterMain .nav a{
    color: #535050;
    font-size: 19px;
    font-family: "Gotham-Book"; 
    font-weight: 400;
    /* line-height: 42px; */
    word-wrap: break-word
}