.button_common {
    background-color: olivedrab;
    color: white;
    padding: 15px 30px;
    border: none;
    font-size: 22px;
    border-radius: 15px;
    margin: 1rem;
    width: 90%;
}
.error {
    color: red;
    text-align: left;
    margin: auto;
    font-size: 16px;
    padding: 0px 1rem;
}