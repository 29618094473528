/* fonts */

@font-face {
    font-family: 'Gotham-Bold';
    src: url('../fonts/Gotham-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Gotham-Black';
    src: url('../fonts/Gotham-Black.woff') format('woff');
    font-weight: 700;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Gotham-ThinItalic';
    src: url('../fonts/Gotham-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;

  }
  
  @font-face {
    font-family: 'Gotham-BookItalic';
    src: url('../fonts/Gotham-BookItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;

  }

  
  @font-face {
    font-family: 'Gotham-XLight';
    src: url('../fonts/Gotham-XLight.woff') format('woff');
    font-weight: 300;
    font-style: normal;

  }
  
  @font-face {
    font-family: 'Gotham-Light';
    src: url('../fonts/Gotham-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;

  }
  
  @font-face {
    font-family: 'Gotham-Medium';
    src: url('../fonts/Gotham-Medium.woff') format('woff');
    font-weight: 700;
    font-style: normal;

  }
  
  @font-face {
    font-family: 'Gotham-Thin';
    src: url('../fonts/Gotham-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;

  }
  
  @font-face {
    font-family: 'Gotham-Book';
    src: url('../fonts/Gotham-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;

  }

  @font-face {
    font-family: 'Gotham-BookItalic';
    src: url('../fonts/Gotham-BookItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;

  }
  
  @font-face {
    font-family: 'Gotham-UltraItalic';
    src: url('../fonts/Gotham-UltraItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;

  }
  
  @font-face {
    font-family: 'Gotham-XLightItalic';
    src: url('../fonts/Gotham-XLightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;

  }




  /* Century */


  @font-face {
    font-family: 'CenturyBoldSWFTE';
    src: url('../fonts/CenturyBoldSWFTE.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Century';
    src: url('../fonts/CENTURY.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'CenturyBoldItalicSWFTE';
    src: url('../fonts/CenturyBoldItalicSWFTE.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Century-Bold';
    src: url('../fonts/Century-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Century-Normal';
    src: url('../fonts/Century-Normal.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'CenturyItalicSWFTE';
    src: url('../fonts/CenturyItalicSWFTE.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'CenturyRegularSWFTE';
    src: url('../fonts/CenturyRegularSWFTE.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

